.cabinet__top-list{
    list-style: none;
    display: flex;
}
.menu-burger__btn{
    display: none;
    width: 30px;
    height: 20px;
    margin-left: 20px;
    z-index: 4;
    position: relative;
    cursor: pointer;
}
.menu-burger__btn span{
    content: '';
    position: absolute;
    width: 30px;
    transition: all ease .3s;
    background-color: #fff;
    height: 3px;
    top: 9px;
}
.menu-burger__btn:before {
    content: '';
    position: absolute;
    width: 30px;
    background-color: #fff;
    height: 3px;
    top: 0;
}
.menu-burger__btn:after{
    content: '';
    position: absolute;
    width: 30px;
    background-color: #fff;
    bottom: 0;
    height: 3px;
}
.menu-burger__btn:after{
    content: '';
    position: absolute;
    width: 30px;
    background-color: #fff;
    bottom: 0;
    height: 3px;
}
.menu-burger__btn.active::before{
    transform: rotate(45deg);
    top: 8px;
}
.cabinet__top-list__item.profile {
    display: flex;
    align-items: center;
}
.cabinet__top-list__item img {
    margin-left: 25px;
}
.menu-burger__btn.active span{
    transform: scale(0);
}
.menu-burger__btn.active::after{
    transform: rotate(-45deg);
    bottom: 9px;
}
.cabinet__top-list__item a{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 18px;
    text-decoration: none;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
}

.cabinet__top-list__item a:hover{
    background-color: #a54dfd41;
}

a.active{
    background-color: #a54dfd41;
}
.cabinet__top-list__item .logout{
    cursor: pointer;
}
@media (max-width: 1200px) {
    .menu-burger__btn{
        display: block;
    }
    .cabinet__top-list{
        z-index: 4;
        position: fixed;
        right: -3500px;
        transition: all ease .3s;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #5F4EA5;
        margin: 0;
    }

    .cabinet__top-list.active{
        right: 0;
    }
    .cabinet__top-list{
        padding-top: 170px;
        padding-left: 30px;
        margin: 0 auto;
        display: block;
    }
    .cabinet__top-list li{
        margin-bottom: 20px;
    }
}
@media (max-width: 1710px) {
    ul.cabinet__top-list > li:not(:last-child) {
        display: flex;
    }
}

@media (max-width: 2048px) {
    .cabinet__title{
        font-size: 25px;
    }
    .cabinet__top-list__item.profile img{
        width: 16px;
        height: 16px;
    }
    .cabinet__top-list__item a{
        font-size: 13px;
    }
}
