@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
    box-sizing: border-box;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    background: rgb(70,67,113);
    background: -moz-linear-gradient(90deg, rgba(70,67,113,1) 2.57%, rgba(36,36,36,1) 122.31%);
    background: -webkit-linear-gradient(90deg, rgba(70,67,113,1) 2.57%, rgba(36,36,36,1) 122.31%);
    background: linear-gradient(90deg, rgba(70,67,113,1) 2.57%, rgba(36,36,36,1) 122.31%);
    background: linear-gradient(90deg, rgba(45, 41, 94, 0.88) 2.57%, rgba(0, 0, 0, 0.86) 122.31%);
    background: linear-gradient(90deg, rgba(70,67,113,1) 2.57%, rgba(36,36,36,1) 122.31%);
    width: 100%;
    height: 100%;
    max-width:100%;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
