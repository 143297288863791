.catalog__wrapper{
    display: flex;
    justify-content: space-between;
    padding-top: 54px;
}
.catalog__track-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.add-invest-mobile{
    display: none;
}
.catalog__left-side{
    flex: 1263px;
    margin-right: 33px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.catalog__right-side{
    flex: 541px;
}
.add-release__popup-wrapper .modal.active {
    justify-content: initial;
    display: block;
    align-items: initial;
    padding: 159px 50px 54px 39px;
    overflow: auto;
}
.catalog__top{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.add-track__button{
    cursor: pointer;
    width: 311px;
    height: 45px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14.7146px;
    letter-spacing: 0.105em;
    color: #FFFFFF;
    background: #A54DFD;
    border-radius: 6.68845px;
    outline: none;
    border: none;
}

.add-track__button:hover{
    background: #a54dfd31;
    border: 1px solid #A54DFD;
}

.pagination__wrapper{
    display: flex;
    justify-content: center;
}
@media (max-width: 1560px) {
    .catalog__wrapper{
        padding-top: 30px;
    }
    .add-release__popup-wrapper .modal.active {
        justify-content: initial;
        display: block;
        align-items: initial;
        padding: 95px 20px 54px 19px;
    }
    .add-track__button{
        width: 232px;
        height: 33px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        letter-spacing: 0.105em;
        color: #FFFFFF;
    }
}

@media (max-width: 1120px) {
    .catalog__wrapper{
        display: block;
    }
    .catalog__left-side{
        margin-right: 0;
    }
    .add-invest-desktop{
        display: none;
    }
    .add-invest-mobile{
        display: block;
    }
}

@media (max-width: 728px) {
    .catalog__wrapper{
        padding-top: 20px;
    }
}

@media (max-width: 460px) {
    .catalog__wrapper{
        padding-top: 15px;
    }
}