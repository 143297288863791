.expenses__dashboard-table{
    width: 100%;
    table-layout: fixed;
    /* margin-top: 42px; */
    min-width: 1000px;
    border-spacing: 0px 15px;
}

.add-invest-mobile{
    display: none;
}
.padding-left, .padding-right {
    width: 30px;
}
.expenses-table__head, .expenses-table__body{
    padding: 0 30px 0 30px;
}
.expenses__dashboard-table:before {
    /* content:"@";
    display:block;
    line-height:21px;
    text-indent:-99999px; */
}
.expenses-table__head{
    height: 53px;
    background: #222222;
    align-items: center;
    border-radius: 9.39668px;
}
.expenses__dashboard-table th:first-child{
    border-radius:10px 0 0 10px;
}

.expenses__dashboard-table th:last-child{
    border-radius:0 10px 10px 0;
    font-size: 18px;
}
.expenses__dashboard-table td:first-child{
    border-radius:10px 0 0 10px;
}

.expenses__dashboard-table td:last-child{
    border-radius:0 10px 10px 0;
}
.expenses-table__head tr{
    border-radius: 9px;
}
.profile__icon-th, .profile__icon-td{
    width: 123px;
}
th,td {
    text-align: center;
}
.expenses-table__head th{
    position: sticky;
    top: 0;
    font-family: "Montserrat";
    background: #222222;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}
.expenses-item__item{
    color: #fff;
    font-family: "Montserrat";
    font-weight: 500;
}
.expenses-item__email{
    font-size: 14px;
    font-weight: 300;
}
.expenses-item__flag{
    width: 84px;
    height: 31px;
    font-family: "Montserrat";
    margin: 0 auto;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.105em;
    font-size: 15px;
    font-weight: 500;
    border-radius: 10px;
}
.expenses-item__flag.isApproved.unapproved{
    color: #FF90E7;
    border: solid 1px #FF90E7;
}
.expenses-item__flag.isApproved.approved{
    color: #1AFF75;
    border: solid 1px #1AFF75;
}
.head-item__select:after {
    content: '<>';
    font: 17px "Consolas", monospace;
    color: #333;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 11px;
    /*Adjust for position however you want*/

    top: 18px;
    padding: 0 0 2px;
    border-bottom: 1px solid #999;
    /*left line */

    position: absolute;
    pointer-events: none;
}

.head-item__select{
    width: 100%;
    background: transparent;
    height: 30px;
    border: 0.805196px solid #505050;
    padding: 0px 8px;
    border-radius: 4.02598px;
    font-family: Montserrat;
    font-weight: 400;
    appearance: none;
    background: url('../../../assets/img/arrow.png') 96% / 15px 10px no-repeat transparent;
    font-size: 18px;
    color: #fff;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    display: block;
    max-width: 320px;
    /*background-image: none;*/
}
.expenses-table__head-item.artist{
    width: 355px;
    padding-right: 71px;
}
.expenses-table__head-item.release_name{
    width: 314px;
    padding-right: 52px;
}
.expenses-table__head-item.category{
    width: 258px;
    padding-right: 70px;
}
.expenses-table__head-item.article{
    width: 253px;
    padding-right: 68px;
}
.expenses-table__head-item.sum{
    font-size: 20px;
    text-align: start;
    width: 120px;
}
.expenses-table__head-item.operation_date{
    font-size: 20px;
    width: 140px;
}
.expenses-table__head-item.isStocked{
    width: 152px;
    padding-right: 29px;
}
.head-item__select option{
    background: #222222;
}
.expenses-item__flag.isPaid.paid{
    color: #1AFF75;
    border: solid 1px #1AFF75;
}
.expenses-item__item{
    text-align: start;
}
.expenses-item__item.operation_date{
    text-align: center;
}
.expenses-item__item.sum{
    padding-left: 0;
}
.expenses-item__item.isStocked{
    padding-left: 0;
}
.expenses-top{
    display: flex;
    justify-content: space-between;
}
.expenses-controls{
    width: 340px;
}
.add-expense__button{
    cursor: pointer;
    width: 100%;
    height: 42px;
    display: flex;
    text-align: center;
    font-weight: 500;
    color: #fff;
    background: transparent;
    align-items: center;
    border: 0.696133px solid #FF90E7;
    border-radius: 2.78453px;
}
.add-expense__button span{
    font-family: "Montserrat";
    text-transform: uppercase;
    margin: 0 auto;
    font-size: 15px;
}
.sum-expenses{
    width: 100%;
    height: 42px;
    display: flex;
    text-align: center;
    font-weight: 500;
    color: #fff;
    background: transparent;
    align-items: center;
    border: 1px solid #1AFF75;
    margin-top: 29px;
    border-radius: 2.78453px;
}
.sum-expenses span{
    font-family: "Montserrat";
    text-transform: uppercase;
    margin: 0 auto;
    font-size: 15px;
}
.expenses-item__item.report{
    width: 132px;
    text-align: center;
    padding-left: 0;
}
.expenses-table__head-item.edit{
    width: 35px;
}
.expenses-item__flag.isPaid.unpaid{
    color: #FF90E7;
    border: solid 1px #FF90E7;
}
.fio{
    width: 323px;
}
.expenses-item__flag span{
    margin: 0 auto;
}
.expenses-table__body tr {
    box-shadow: 0px 3.59145px 11.6722px rgba(30, 30, 30, 0.29);
}
.expenses-table__body td {
    background: rgba(112, 143, 255, 0.5);
    padding: 6px 0 6px 15px;
}
.send-in-tg-th, .send-in-tg-td{
    width: 230px;
    padding-right: 85px !important;
    padding-left: 25px !important;
}

@media (max-width: 2048px) {
    .head-item__select{
        font-size: 14px;
    }
    .padding-left, .padding-right{
        width: 26px;
    }
    .expenses-table__head-item.edit{
        width: 17px;
    }

    .expenses-table__head-item.artist{
        width: 248px;
        padding-right: 50px;
    }
    .expenses-table__head-item.release_name{
        width: 219px;
        padding-right: 37px;
    }
    .expenses-table__head-item.category{
        width: 200px;
        padding-right: 70px;
    }
    .expenses-table__head-item.article{
        width: 187px;
        padding-right: 52px;
    }
    .expenses-table__head-item.sum{
        font-size: 14px;
        text-align: start;
        width: 85px;
    }
    .expenses-item__item.edit img{
        width: 17px;
    }
    .expenses-table__head-item.operation_date{
        font-size: 14px;
        width: 110px;
    }
    .expenses-table__head-item.isStocked{
        width: 120px;
        padding-right: 36px;
    }
    .expenses-table__head-item.report{
        width: 92px;
    }
}

@media (max-width: 1560px) {
    .expenses-top{
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
    }
    .expenses-controls{
        width: 236px;
    }
    .add-expense__button{
        cursor: pointer;
        width: 100%;
        height: 29px;
        display: flex;
        text-align: center;
        font-weight: 500;
        color: #fff;
        background: transparent;
        align-items: center;
        border: 0.696133px solid #FF90E7;
        border-radius: 2.78453px;
    }
    .add-expense__button span{
        font-family: "Montserrat";
        text-transform: uppercase;
        margin: 0 auto;
        font-size: 11px;
    }
    .sum-expenses{
        width: 100%;
        height: 29px;
        display: flex;
        text-align: center;
        font-weight: 500;
        color: #fff;
        background: transparent;
        align-items: center;
        border: 1px solid #1AFF75;
        margin-top: 29px;
        border-radius: 2.78453px;
    }
    .sum-expenses span{
        font-family: "Montserrat";
        text-transform: uppercase;
        margin: 0 auto;
        font-size: 11px;
    }
    .expenses-item__item{
        font-size: 12px;
    }
    .expenses-table__head th{
        font-size: 16px;
    }
    .expenses__dashboard-table th:last-child{
        font-size: 10px;
    }
}

@media (max-width: 812px) {
    .expenses-top{
        display: block;
    }
}