.dashboard__container{
    width: 100%;
    /*height: 790px;*/
    background: #5F4EA5;
    margin-top: 20px;
    overflow: hidden;
    box-shadow: 0px 4px 13px rgba(255, 255, 255, 0.1);
    filter: drop-shadow(0px 3.4264px 3.4264px rgba(0, 0, 0, 0.25));
    border-radius: 9px;
    position: relative;
}
.successful-message__popup{
    padding: 25px;
    background: #222222;
    border-radius: 20px;
    font-family: 'Montserrat';
    color: #ffff;
}
.background-circle{
    position: absolute;
    width: 804px;
    height: 804px;
    left: -424px;
    border-radius: 50%;
    top: -260px;
    background: linear-gradient(162.05deg,
    rgba(255, 153, 251, 0.49) 12.24%,
    rgba(71, 75, 164, 0.73) 40.34%,
    rgba(38, 34, 238, 0.15) 88.24%);
}
.dashboard__top{
    width: 100%;
    display: flex;
    position: relative;
    z-index: 3;
    padding: 24px 0px 24px 0px;
    background: #fff;
}
.period-name, .count-name, .sum-name{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
}
.period-name{
    flex: none;
    width: 40%;
    text-align: center;
}

.count-name{
    flex: none;
    width: 12.5%;
    text-align: center;
    padding-right: 23px;
}

.sum-name{
    flex: none;
    width: 27%;
    padding-right: 10px;
    text-align: center;
}
.dashboard__list{
    padding: 0 46px 0 47px;
}
.dashboard__list-item{
    position: relative;
    z-index: 3;
}

.list__item-quarter, .list__item-auditions, .list__item-total{
    font-family: 'Montserrat';
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 17.132px;
    line-height: 24px;
    color: #FFFFFF;
}
.dashboard__list{
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    margin: 0 auto;
}
.dashboard__list-item{
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    background: rgba(112, 143, 255, 0.5);
    box-shadow: 0px 4px 13px rgba(30, 30, 30, 0.29);
    border-radius: 9px;
    padding: 10px 47px 10px 33px;
    margin-bottom: 20px;
}
.list__item-quarter, .list__item-auditions, .list__item-total{}
.list__item-doc__wrapper {
    flex: none;
    width: 3%;
}
.list__item-edit{
    flex: none;
    width: 8%;
}
.list__item-edit img{
    cursor: pointer;
}
.list__item-doc{
    cursor: pointer;
}
.list__item-quarter{
    flex: none;
    width: 30%;
}
.list__item-auditions{
    flex: none;
    width: 25%;
}
.list__item-total{
    flex: none;
    overflow-wrap: anywhere;
    width: 20.5%;
}
.list__item-download-btn__wrapper{
    flex: none;
    width: 13%;
}
.list__item-download-btn{
    cursor: pointer;
    width: 100%;
    height: 37px;
    outline: none;
    border: none;
    background: #FFFFFF;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    border-radius: 30px;
}

.total__payment{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    margin-bottom: 21px;
    color: #FFFFFF;
}
.dashboard__list-wrapper{
    min-width: 783px;
    overflow-y: auto;
    overflow-x: unset !important;
    max-height: 600px;
}
.request-reward{
    cursor: pointer;
    width: 379px;
    height: 37px;
    background: #A54DFD;
    outline: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.105em;
    color: #FFFFFF;
    border: none;
    border-radius: 30px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.users__inner{
    padding-top: 25px;
}
.add-user__btn__inner{
    display: flex;
    justify-content: end;
}
.add-user__btn{
    cursor: pointer;
    width: 324px;
    height: 47px;
    display: flex;
    align-items: center;
    background: transparent;
    border: 1.3936px solid #A54DFD;
    border-radius: 6.96803px;
    filter: drop-shadow(0px 2.45866px 2.45866px rgba(0, 0, 0, 0.25));
}

.add-user__btn:hover{
    background: #a54dfd41;
}    
.add-user__btn p {
    margin: 0 auto;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 15.3297px;
    letter-spacing: 0.105em;
    color: #FFFFFF;
}
.lds-ring {
    display: inline-block;
    margin: 0 auto;
    position: relative;
    width: 80px;
    padding-bottom: 200px;
    height: 80px;
}
.no-reports{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
}
.no-reports div{
    margin: 0 auto;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10px;
    font-size: calc(20px + 20 * (100vw / 1920));
    color: #fff;
}
.no-reports div span{
    padding-left: 10px;
    font-weight: 600;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}
.dashboard__container{
    height: 100%;
}
.request-reward{
    margin-bottom: 42px;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.total__payment{
    position: relative;
    z-index: 3;
}
/*todo: avoid this poop code*/
@media (max-width: 1720px) {
}

.lds-ring__container{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}
/* @media (max-width: 1690px) {
    .period-name, .count-name, .sum-name{
        font-size: 16px;
    }
} */

@media (max-width: 2048px) {
    .period-name, .count-name, .sum-name{
        font-size: 14px;
    }
    .period-name{
        padding-left: 31px
    }
    .list__item-edit img{
        width: 22px;
        height: 22px;
    }

    .add-user__btn{
        width: 232.96px;
        height: 33.74px;
    }
    .add-user__btn p {
        font-size: 11px;
    }
    .total__payment{
        margin-bottom: 13px;
    }
    .logo__wrapper .logo{
        height: 15px;
    }
    .dashboard__list-item {
        padding: 8px 47px 7px 33px;
        margin-bottom: 15px;
    }
    .list__item-download-btn {
        height: 22px;
    }
    .list__item-quarter, .list__item-auditions, .list__item-total {
        font-size: 12px;
    }
    .list__item-download-btn{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 9.21996px;
        letter-spacing: 0.105em;
        color: #000000;
    }
    .dashboard__list-wrapper{
        max-height: 420px;
    }
    .dashboard__top{
        padding: 15px 0px 14px 0px;
    }
    .request-reward{
        width: 232px;
        height: 22px;
        font-size: 9px;
        letter-spacing: 0.105em;
        text-transform: uppercase;
    }
    .total__payment{
        font-size: 14px;
    }
    .list__item-doc img {
        width: 29px;
        height: 29px;
    }
}

@media (max-width: 1140px) {
    .dashboard__list {
        padding: 47px 15px 0 15px;
    }
}

@media (max-width: 1024px) {
    .list__item-download-btn__wrapper{
        display: none;
    }
    .dashboard__list {
        padding: 47px 15px 0 15px;
    }
    .dashboard__list-item{
        padding: 14px 12px 13px 12px;
    }
    /*.list__item-quarter{*/
    /*    width: 42%;*/
    /*}*/
    /*.list__item-auditions{*/
    /*    width: 38%;*/
    /*}*/
    /*.list__item-total{*/
    /*    width: 17.5%;*/
    /*}*/

}


@media (max-width: 995px) {
    .dashboard__list-item{
        padding: 14px 12px 13px 12px;
    }
    .count-name{
        padding-right: 0px;
    }
    .list__item-quarter{
        width: 38%;
    }
    .list__item-auditions{
        width: 28%;
    }
    .list__item-total{
        width: 17.5%;
        padding-right: 55px;
    }
    .count-name{
        width: 27.5%;
    }
    .sum-name{
        width: 12%;
    }
}
@media (max-width: 768px) {
    .dashboard__list-item{
        width: 766px;
    }
    .dashboard__top{
        width: 797px;
    }
    .period-name{
        padding-left: 50px;
    }
    .count-name{
        padding-left: 15px;
    }
    .dashboard__container{
        height: 100%;
    }
    .dashboard__container{
        overflow: auto;
    }
}
