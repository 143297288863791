
.login__container {
    padding: 205px 62px 66px 62px;
    box-sizing: border-box;
    text-align: center;
}
.login__logo{
    width: 170px;
    height: 24px;
    margin-bottom: 100px;
}
.button__text{
    text-transform: uppercase;
    letter-spacing: 0.135em;
    color: #fff;
    font-family: 'Montserrat';
    font-style: normal;
    margin: 0 auto;
    font-weight: 700;
    font-size: 20px;
}
.login__form-button{
    position: relative;
    display: flex;
    align-items: center;
}
.button_text{
    margin: 0 auto;
}
.login__form-button.button--loading .button_text {
    visibility: hidden;
    opacity: 0;
}

.button--loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}
.login {
    max-width: 643px;
    box-sizing: border-box;
    position: relative;
    min-height: 410px;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 150px;
    padding: 26px 103px 39px 103px;
    background: rgba(95, 78, 165, 0.3);
    border-radius: 5px;
}
.bg-circle{
    z-index: -10;
    position: absolute;
    width: 804px;
    left: -424px;
    top: -260px;
    height: 804px;
    border-radius: 50%;
    background: linear-gradient(162.05deg, rgba(255, 153, 251, 0.147) 12.24%, rgba(71, 75, 164, 0.219) 40.34%, rgba(38, 34, 238, 0.045) 88.24%);
    mix-blend-mode: color;
}
.login__form-title{
    color: #fff;
    position: relative;
    font-family: 'Montserrat';
    padding-bottom: 26px;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    z-index: 3;
    text-transform: uppercase;
}

.login__form{
    z-index: 3;
    position: relative;
    width: 100%;
}

.login__form-field{
    text-align: left;
}
.login__form-field {
    padding-bottom: 25px;
    position: relative;
}
.login__form-field.last-child{
    padding-bottom: 30px;
}
.login__form-field label {
    display: block;
    padding-bottom: 3px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18.3036px;
    color: #FFFFFF;
}
.login__form-input{
    transition: all ease .2s;
    width: 100%;
    height: 46px;
    font-size: 16px;
    padding-left: 21px;
    font-weight: 300;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
}

.login__form-input:hover{
    outline: 2px solid #BB77FF;
}

.login__logo{
    display: initial;
}
.login__logo.mobile{
    display: none;
}
.login__form-input:focus {
    outline: 2px solid #BB77FF;
}
.login__form-button{
    text-transform: uppercase;
    cursor: pointer;
    transition: all ease .5s;
    width: 100%;
    background: transparent;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16.1515px;
    letter-spacing: 0.135em;
    color: #FFFFFF;
    border: 1px solid #A54DFD;
    border-radius: 7.66072px;
    height: 46px;
}

.login__form-button:hover{
    background: #a54dfd41;
}

.login__form-error{
    font-weight: 300;
    text-align: right;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    right: 0;
    padding-top: 7px;
    font-size: 15px;
    font-family: 'Montserrat';
    font-style: normal;
    color: #FF7E9D;
}
.login__form-input.login-error{
    outline: 2px solid #FF7E9D;
}
.login__form-props{
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    align-items: center;
}

.remember-me{
    display: flex;
    align-items: center;
}

.remember-me p {
    padding-left: 7px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 16.8393px;
    color: #FFFFFF;
}

.remember-me input {
    width: 14px;
    height: 14px;
    background: #FFFFFF;
    border-radius: 2px;
}
.remember-me input{
    accent-color: aquamarine;
}

.forgot-password a{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    text-decoration: none;
    color: #fff;
    font-size: 23px;
}
.login__title div{
    color: #FF7E9D;
}
@media (max-width: 2048px) {
    .login__container {
        padding: 80px 39px 38px 39px;
    }
    .login__title {
        font-size: 35px;
    }
    .login {
        max-width: 517px;
        min-height: 330px;
        margin-bottom: 54px;
        padding: 21px 83px 33px 83px;
    }
    .login__form-field.last-child {
        padding-bottom: 20px;
    }
    .login__form-title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        padding-bottom: 21px;
        color: #FFFFFF;
    }
    .login__form-field {
        padding-bottom: 20px;
    }
    .login__form-field label {
        padding-bottom: 3px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14.7321px;
        color: #FFFFFF;
    }
    .login__form-input {
        height: 37px;
        font-family: 'Montserrat';
        font-size: 15px;
    }
    .login__form-password{

    }
    .login__form-props {
        margin-bottom: 16px;
    }
    .remember-me input {
        background: #FFFFFF;
        opacity: 0.1;
        width: 12px;
        height: 12px;
    }
    .remember-me p {
        font-size: 13px;
        font-weight: 300;
    }
    .forgot-password a {
        font-size: 17px;
    }
    .login__form-button {
        font-size: 11px;
        height: 37px;
        margin-top: 21px;
    }
}
@media (max-width: 768px) {
    .login__container{
        padding: 15px 20px 15px 20px;
    }
}
@media (max-width: 800px) {
    .login{
        padding: 10px 30px 10px 30px;
    }
}

@media (max-width: 565px) {
    .login__form-props{
        display: block;
        text-align: center;
    }
    .remember-me {
        margin-bottom: 25px;
    }
}

@media (max-width: 1016px) {
    .login__title{
        top: 40px;
        left: 35px;
    }
}

@media (max-width: 932px) {
    .login__title{
        top: 30px;
        left: 20px;
        font-size: 33px;
    }
}

@media (max-width: 425px) {
    .bg-circle{
        width: 545px;
        left: -424px;
        top: -433px;
    }
    .login{
        min-height: 360px;
        padding: 18px 31px 10px 31px;
    }
    .login__logo{
        display: none;
    }
    .login__logo.mobile{
        display: initial;
        margin-top: 76px;
    }
    .login__form-error{
        padding-top: 5px;
    }
    /*.login__form-title{*/
    /*    font-size: 30px;*/
    /*}*/
    .login__form-field label{
        font-size: 13px;
    }
    .login__form-field{
        padding-bottom: 20px;
    }
    .login__form-button{
        height: 42px;
    }
    .login__form-input{
        height: 35px;
    }
    .remember-me p {
        font-size: 15px;
    }
    .forgot-password a {
        font-size: 15px;
    }
}
.remove-btn {
    margin-right: 0.5rem;
}

.item-enter {
    opacity: 0;
}
.item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}
.item-exit {
    opacity: 1;
}
.item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}

.server__errors{
    width: 100%;
    height: 100%;
    background: rgba(255, 126, 157, 0.64);
    border: 1px solid #DD96A7;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 30px;
}
.server__errors p {
    padding-top: 3.5px;
    padding-bottom: 3.5px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
}
