.upload-sales-report-form {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}

.upload-sales-report-form.active {
    display: flex;
}

.form-container {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 550px;
    text-align: center;
}

.upload-sales-report-stats-table td {
    text-align: left;
}

hr {
    margin: 10px;
}

.form-group {
    margin-bottom: 15px;
}

.form-actions {
    display: flex;
    justify-content: space-between;
}

.process-button,
.close-button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.process-button {
    background-color: #4CAF50;
    color: white;
}

.close-button {
    background-color: #f44336;
    color: white;
}
