*{
    padding: 0;
    margin: 0;
}
.App {
    min-width: 100%;
    min-height: 100%;
}
.lds-ring__container.app_{
    height: 100vh;
}
.lds-ring__container.app_ .lds-ring div{
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 4px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}