.track__list-item{
    background: #222222;
    box-shadow: 0px 3.28892px 10.689px rgba(0, 0, 0, 0.1);
    border-radius: 6.68845px;
}

.track__list-item:hover{
    background: #262626;
    box-shadow: none;
}

.release__info-container{
    padding: 37px 37px 24px 35px;
}

.track__top{
    display: flex;
    justify-content: space-between;
}

.track__info{
    display: flex;
}

.track-image__container{
    margin-right: 22px;
}

.track__title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.track__artist{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    width: fit-content;
    margin-bottom: 19px;
}

.track__upc, .track__label{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.track__upc span, .track__label span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
}

.track-tools img{
    cursor: pointer;
    /* margin-left: 30px; */
}
.track-tools{
    display: flex;
    justify-content: center;
}

.track__bottom{
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
}

.release-date{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 16px;
    color: #FFFFFF;
}

.release-date span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
}

.track-list__btn{
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    font-size: 16px;
    color: #FFFFFF;

    padding: 5px 10px;
    border-radius: 5px;
}

.track-list__btn:hover{
    background: #a54dfd31;
}
.track-list__btn.open{
    background: #a54dfd31;  
}

.track-list__arrow-close, .track-list__arrow-open{
    display: inline-block;
}
.track-list__arrow-close{
    transform: rotate(90deg);
}
.track-list__arrow-open{
    transform: rotate(0deg);
}
.add-invest__btn{
    cursor: pointer;
    text-transform: uppercase;
    height: 100%;
    filter: drop-shadow(0px 3.28892px 3.28892px rgba(0, 0, 0, 0.25));
    border: 1.33769px solid #A54DFD;
    border-radius: 6.68845px;
    background: transparent;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12.0392px;
    letter-spacing: 0.105em;
    color: #FFFFFF;
    padding: 0 25px 0 25px;
}
.track-list__wrapper{
    padding-top: 25px;
}
.track-list__names{
    border-top: 0.668845px solid rgba(255, 255, 255, 0.3);
    padding-top: 16px;
    padding-bottom: 19px;

}
.track-list__names__inner{
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
}
.track-list__names div{
    font-family: 'Montserrat';
    flex: none;
    font-style: normal;
    font-weight: 400;
    font-size: 12.6668px;
    color: #FFFFFF;
}
.track-list__names-empty{
    width: 24px;
}
.track-list__names-number{
    width: 50px;
}
.track-list__names-name{
    width: 120px;
}
.track-list__names-artist{
    width: 200px;
}
.track-list__names-length{
    width: 110px;
    text-align: center;
}
.track-list__names-rights-percentage{
    width: 300px;
}
.track-list__values{
    border-top: 0.668845px solid rgba(255, 255, 255, 0.3);
    padding-top: 10px;
    padding-bottom: 10px;
}
.delete_track_icon{
    padding: 3px;
    border-radius: 5px;
    height: 28px;
    width: 28px;
}
.delete_track_icon:hover{
    background: #a54dfd31;
}
.track-list__values__inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
    /*padding-left: 155px;*/
}
.track-list__values__inner__left {

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}
.track-list__names__inner__left {

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}
.track-list__values-rights-button__container{
    cursor: pointer;
}
.track-list__values-number{
    width: 50px;
}
.track-list__values-rights-button{
    cursor: pointer;
    height: 30px;
    width: 95px;
    outline: none;
    border: none;
    background: transparent;
    border: 0.901606px solid #A54DFD;
    border-radius: 4.50803px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 8.11446px;
    letter-spacing: 0.105em;
    color: #FFFFFF;

}
.track-list__values div{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12.6668px;
    /*padding-right: 28px;*/
    color: #FFFFFF;
}
.track-list__values__item:last-child{
    /*width: 100%;*/
    /*text-align: right;*/
}
.track-list__values-name{
    width: 120px;

}
.track-list__values-artist{
    width: 200px;
}
.track-list__values-length{
    width: 110px;
    text-align: center;
}
.track-list__values-rights-percentage{
    width: 300px;
}
.track-list__values-added-by {
    color: #FFFFFF;
}