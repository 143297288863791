.cabinet__bottom {
    padding-top: 25px;
}
.reports__dashboard-table {
    width: 100%;
    table-layout: fixed;
    min-width: 1300px;
    border-spacing: 0px 15px;
}

.reports__dashboard {
    overflow: auto;
}

.reports__dashboard-table:before {
    /* content: "@";
    display: block;
    line-height: 21px;
    text-indent: -99999px; */
}
.add-report__btn__inner{
    display: flex;
    justify-content: flex-end;
}
.add-report__btn{
    cursor: pointer;
    width: max-content;
    max-width: 400px;
    height: fit-content;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: #FFFFFF;
    border: 1px solid #A54DFD;
    border-radius: 8.94991px;
    background: transparent;
    padding: 10px 20px;
}
.add-report__btn:hover{
background: #a54dfd31;
border: 1px solid #A54DFD;
}

.reports-table__head {
    height: 79px;
    background: #222222;
    align-items: center;
    border-radius: 9.39668px;
}

.reports__dashboard-table th:first-child {
    border-radius: 10px 0 0 10px;
}

.reports__dashboard-table th:last-child {
    border-radius: 0 10px 10px 0;
    font-size: 18px;
}

.reports__dashboard-table td:first-child {
    border-radius: 10px 0 0 10px;
}

.reports__dashboard-table td:last-child {
    border-radius: 0 10px 10px 0;
}

.reports-table__head tr {
    border-radius: 9px;
}

.profile__icon-th,
.profile__icon-td {
    width: 123px;
}

.document__icon_img {
    cursor: pointer;
}


th,
td {
    text-align: center;
}

.reports-table__head th {
    position: sticky;
    top: 0;
    font-family: "Montserrat";
    background: #222222;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
}

.reports-item__item {
    color: #fff;
    font-size: 18px;
    font-family: "Montserrat";
    font-weight: 500;
}

.reports-item__email {
    font-size: 11px;
    font-weight: 300;
}

.reports-item__flag {
    width: 84px;
    height: 31px;
    font-family: "Montserrat";
    margin: 0 auto;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.105em;
    font-size: 15px;
    font-weight: 500;
    border-radius: 10px;
}

.reports-item__flag.isSentOut.sent {
    color: #1AFF75;
    border: solid 1px #1AFF75;
    cursor: default;
}

.reports-item__flag.isSentOut.notsent {
    color: #FF90E7;
    border: solid 1px #FF90E7;
    cursor: pointer;
}

.reports-item__flag.isApproved.unapproved {
    color: #FF90E7;
    border: solid 1px #FF90E7;
    cursor: default;
}

.reports-item__flag.isApproved.approved {
    color: #1AFF75;
    border: solid 1px #1AFF75;
    cursor: default;
}

.reports-item__flag.isPaid.paid {
    color: #1AFF75;
    border: solid 1px #1AFF75;
    cursor: default;
}

.reports-item__flag.isPaid.unpaid {
    color: #FF90E7;
    border: solid 1px #FF90E7;
    cursor: pointer;
}

.fio {
    width: 323px;
}

.reports-item__flag span {
    margin: 0 auto;
}

.reports-table__body tr {
    box-shadow: 5px 5px 10px rgba(30, 30, 30, 0.29);
}

.reports-table__body td {
    background: rgba(112, 143, 255, 0.5);
    padding: 6px 0 6px 0;
}

.send-in-tg-th,
.send-in-tg-td {
    width: 230px;
    padding-right: 85px !important;
    padding-left: 25px !important;
}

@media (max-width: 2048px) {
    .reports-item__item {
        font-size: 12px;
    }

    .reports-table__head {
        height: 53px;
    }

    .reports-table__head th {
        font-size: 13px;
    }

    .reports-item__flag {
        font-size: 10px;
        height: 30px;
        cursor: pointer;
    }

    .profile__icon-td img {
        width: 28px;
        height: 28px;
    }

    .reports__dashboard-table th:last-child {
        width: 185px;
        font-size: 13px;
    }
}