.add-release__wrapper {
    width: 100%;
    max-width: 700px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.add-release__form__wrapper {
    margin-bottom: 40px; 
}

.add-release__form__inner{
    max-width: 700px;
    box-sizing: border-box;
    height: 100%;
    background: #313131;
    border: 0.58215px solid #575757;
    box-shadow: 0px 2.3286px 7.56796px rgba(0, 0, 0, 0.1);
    border-radius: 4.73552px;
    padding: 40px;
}

.add-release__txt-inputs{
    display: flex;
    justify-content: space-between;
}

.add-release-licenses__info {
    display: flex;
    justify-content: space-between;
}

.add-release__txt-input {
    height: 40px;
    width: 250px;
    background-color: rgb(52, 52, 52);
    border-radius: 5px;
    border: 1px solid rgba(171, 171, 171, 0.41);
    color: rgb(241, 241, 241);
    padding: 0px 10px;
}

.add-release__txt-input:hover {
    border: 1px solid rgb(238, 238, 238);
}

.add-release__label {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 500;
}

.add-release__popup-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.add-release__form__title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
}

.add-release__form__title span{
    padding-right: 8px;
}

.add-release__form__step{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 21.5962px;
    color: #FFFFFF;
}
.release__form-info__title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #A8A8A8;
    margin-bottom: 25px;
}

.track-info__row{
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(5, 360px);
    grid-template-rows: repeat(2, 45px);
    justify-content: space-between;
}

.track-info__row-item label{
    display: block;
}

.track-info__row-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18.4288px;
    color: #FFFFFF;
    margin-bottom: 11px;
}



.track-info__row-input{
    background: #222222;
    border: 0.870287px solid #B5B5B5;
    border-radius: 4.35144px;
    width: 100%;
    height: 45px;
    font-size: 21px;
    color: #fff;
    padding-left: 10px;
}

.add-release__form__btns{
    display: flex;
    justify-content: space-between;
}
.add-release__form-btn{
    cursor: pointer;
    width: 281.03px;
    height: 49.73px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 19.6898px;
    letter-spacing: 0.105em;
    color: #FFFFFF;
    border: 1px solid #A54DFD;
    border-radius: 8.94991px;
    background: transparent;
}

.add-release__form-btn:hover{
    background: #a54dfd31;
    border: 1px solid #A54DFD;
}

.add-release__txt-inputs__item{
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 65px;
}
.add-release__item-title{
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18.4281px;
    color: #FFFFFF;
    margin-bottom: 12px;
}
.add-release-track__info{
    margin-bottom: 40px;
}

.add-release-licensors__info {
    margin-bottom: 40px;
}

.add-release__item-input{
    width: 447px;
    height: 45px;
    background: #222222;
    font-size: 21px;
    padding-left: 10px;
    color: #FFFFFF;
    border: 0.870257px solid #B5B5B5;
    border-radius: 4.35129px;
}
.add-release__next-btn__wrapper{
    display: flex;
    justify-content: flex-end;
}
.add-release__next-btn{
    cursor: pointer;
    width: 281.02px;
    height: 49.73px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 19.6891px;
    background: transparent;
    border: 0.776201px solid #575757;
    border: 1.78992px solid #A54DFD;
    border-radius: 8.9496px;
    box-shadow: 0px 3.1048px 10.0906px rgba(0, 0, 0, 0.1);
    border-radius: 6.31402px;
    text-transform: uppercase;
    letter-spacing: 0.105em;
    color: #FFFFFF;
}
.add-release__image-input{
    display: flex;
    align-items: center;
}
.add-release__image-accepted-formats p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13.4892px;
    color: #858585;
}
.split_author_licensor_rights {
    background-color: #282828;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.custom-checkbox {
    position: relative;
    display: flex;
    align-items: center;
}
.custom-checkbox input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
}
.custom-checkbox label {
    position: relative;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: pointer;
}
.custom-checkbox input[type="checkbox"]:checked + label::after {
    content: "\2713";
    font-size: 30px;
    color: #A54DFD;
    font-weight: bold; /* Сделать галочку толще */
    line-height: 5px;
}

@media (max-width: 1770px) {
    .add-release__image-accepted-formats p{
        font-size: 10px;
    }
    .add-release__item-input{
        width: 335px;
        height: 35px;
    }
    .add-release__item-title{
        font-size: 14px;
    }
}

@media (max-width: 1650px) {
    .track-info__row{
        grid-template-columns: repeat(5, 270px);
        grid-template-rows: repeat(2, 35px);
    }
    .add-release__form__step{
        font-size: 16px;
    }
    .add-release__next-btn{
        width: 210.76px;
        height: 37.3px;
        font-size: 14.7668px;
    }
    .track-info__row-input{
        height: 35px;
    }
    .add-release__form-btn{
        width: 210px;
        height: 37px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.105em;
        color: #FFFFFF;
    }
    .track-info__row-title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #FFFFFF;
        margin-bottom: 11px;
    }
}

@media (max-width: 1560px) {

    .add-release__item-title{
        margin-bottom: 9px;
    }

    .release__form-info__title{
        margin-bottom: 20px;
        font-size: 14px;
    }
    .track-info__row-item label{
        font-size: 14px;
        margin-bottom: 8px;
    }
    .add-release-track__info{
        grid-gap: 0.3rem;
    }
    .add-release__form__btns{
        margin-top: 40px;
    }
}

@media (max-width: 1280px) {
    .track-info__row{
        grid-template-columns: repeat(2, 340px);
        justify-content: center;
        grid-template-rows: repeat(2, 85px);
    }
}


@media (max-width: 820px) {
    .track-info__row{
        grid-template-columns: repeat(2, 240px);
    }
    .add-release__txt-inputs{
        display: block;
    }
}

@media (max-width: 560px) {
    .track-info__row{
        display: block;
    }
    .track-info__row-item{
        margin-bottom: 10px;
    }
    .add-release__form-btn{
        width: 160px;
        height: 34px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 11.2102px;
        letter-spacing: 0.105em;
        color: #FFFFFF;
    }
    .add-release__form__inner{
        padding: 14px;
    }
}

@media (max-width: 460px) {
    .add-release__item-input{
        width: 100%;
    }
    .add-release__next-btn{
        width: 160px;
        height: 28.31px;
        font-size: 11px;
    }
    .add-release__form__title{
        font-size: 14px;
    }
    .add-release__form__step{
        font-size: 14px;
    }
}
@media (max-width: 400px) {
    .add-release__form-btn{
        width: 120px;
    }
}
