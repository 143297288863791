.add-spending__form__container{
    background: #222222;
    box-shadow: 0px 3.28892px 10.689px rgba(0, 0, 0, 0.1);
    border-radius: 6.68845px;
    width: 100%;
    padding: 17px 25px 31px 30px;
}

.add-spending__form__title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 30px;
    font-size: 13px;
}

.add-spending__form-label{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    display: block;
    color: #FFFFFF;
    margin-bottom: 12px;
}

.add-spending__form-inputs {
    display: flex;
}

.add-spending__form-inputs-left {
    margin-right: 30px;
    margin-bottom: 20px;
}

.add-spending__form-input{
    background: transparent;
    border: 0.862713px solid #FFFFFF;
    border-radius: 4.31357px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    padding-left: 15px;
    color: #FFFFFF;
}

.add-spending__form .add-spending__form-input{
    height: 33px;
    width: 200px;
    margin-bottom: 15px;
}

.add--spending__form-btn__wrapper{
    display: flex;
    justify-content: center;
}

.add--spending__form-btn {
    cursor: pointer;
    width: 229px;
    height: 33px;
    filter: drop-shadow(0px 3.24058px 3.24058px rgba(0, 0, 0, 0.25));
    background: transparent;
    border: 1.31803px solid #A54DFD;
    border-radius: 6.59015px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 8.86772px;
    letter-spacing: 0.105em;
    line-height: 11px;
    color: #FFFFFF;
}

@media (max-width: 460px) {
    .add-spending__form-inputs{
       flex-wrap: wrap;
    }
    .add--spending__form-btn__wrapper {
        justify-content: left;
    }
    .add--spending__form-btn{
        width: 200px;
    }
}