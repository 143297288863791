.pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.pagination_nav{
    width: 400px;
}
.arrow-left, .arrow-right{
    cursor: pointer;
    font-family: 'Montserrat';
    background: transparent;
    border: none;
    outline: none;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    color: #FFFFFF;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center; /* Центрирование содержимого по вертикали */
    justify-content: center;
}
.arrow-left:hover, .arrow-right:hover {
    background: #a54dfd31;
    border-radius: 50px;
}
.pagination__number span{
    margin: 0 auto;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 17.8243px;
    letter-spacing: 0.105em;
    color: #FFFFFF;
}
.pagination div{

}
.pagination__number.last{

}
.pagination{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 17.8243px;
    letter-spacing: 0.105em;
    color: #FFFFFF;
}
.pagination__number{
    cursor: pointer;
    border: 0.418028px solid #A54DFD;
    border-radius: 2.09014px;
    padding: 7px;
    min-width: 30px;
    height: 30px;
    display: flex;
    text-align: center;
    align-items: center;
}
.pagination__number:hover {
    background-color: #a54dfd31;
    border-color: #A54DFD;
}
.pagination__number.active{
    background: #A54DFD;
}
.desktop-btns{
    display: flex;
    gap: 10px;
}
.mobile-btns{
    display: none;
}
.desktop-btns, .mobile-btns{
    margin-right: 0 !important;
}
@media (max-width: 425px) {
    .desktop-btns{
        display: none;
    }
    .mobile-btns{
        display: flex;
    }
    .pagination__wrapper{
        justify-content: center;
    }
    .pagination{
        margin: 0 auto;
    }
}