.cabinet__container{
    padding: 38px;
}

.cabinet__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cabinet__title{
    font-family: 'Montserrat';
    font-style: normal;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: calc(20px + 20 * (100vw / 1920));
    color: #fff;
}

.cabinet__title span {
    display: block;
    color: #ff7e90;
    text-transform: none;
}

.cabinet__top-list{
    list-style: none;
    display: flex;
}

.cabinet__top-list__item a{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-decoration: none;
    color: #fff;
}
.cabinet__my-profile{
}
.cabinet__my-profile img{
    padding-left: 13px;
}
.cabinet__my-profile-wrapper{
    display: flex;
    align-items: center;
}
.cabinet__my-profile-wrapper img{
    cursor: pointer;
}
.cabinet__my-profile{
    font-family: 'Montserrat';
    font-style: normal;
    margin-right: 13px;
    font-size: calc(18px + 5 * (100vw / 1920));
    text-decoration: none;
    font-weight: 700;
    color: #FFFFFF;
}
.active{
    color: #A8BBFF;
}
.logo__wrapper{
    text-align: right;
}

@media (max-width: 1490px) {
    .cabinet__container{
        padding: 38px 20px 54px 20px;
    }
}

@media (max-width: 425px) {
    .cabinet__container{
        padding: 38px 5px 54px 5px;
    }
    .logo{
        width: 150px;
    }
}

@media (max-width: 1560px) {
    .cabinet__my-profile-wrapper{
        display: none;
    }
}
@media (max-width: 1200px) {
    .cabinet__my-profile-wrapper{
        display: flex;
    }
    .cabinet__my-profile{
        font-size: 16px;
    }
    .cabinet__my-profile-wrapper img{
        width: 20px;
        height: 20px;
    }
}