.modal {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    transform: scale(0);
}

.cross-close {
    cursor: pointer;
    position: absolute;
    top: 25px;
    left: 10px;
}

.cross-close:before,
.cross-close:after {
    content: "";
    position: absolute;
    width: 14px;
    height: 2px;
    background: gray;
}

.cross-close:before {
    transform: rotate(45deg);
}

.cross-close:after {
    transform: rotate(-45deg);
}

.modal.active {
    visibility: visible;
    transform: scale(1);
}

.modal.active .modal_content {
    visibility: visible;
    transform: scale(1);
}

.modal_content {
    transition: all ease .3s;
    padding: 0px;
    border-radius: 12px;
    visibility: hidden;
    transform: scale(0);    
    width: 700px;
    margin-left: auto;
    margin-right: auto;
}