.edit-user__form__container{
    background: #222222;
    box-shadow: 0px 3.28892px 10.689px rgba(0, 0, 0, 0.1);
    border-radius: 6.68845px;
    width: 465px;
    padding: 23px 28px 31px 37px;
}

.edit-user__form-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 21.4083px;
    color: #FFFFFF;
    margin-bottom: 53px;
}
.edit-user__form label span {
    font-weight: 300;
}
.edit-user__form label {
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 12px;
}
.edit-user__form input {
    width: 100%;
    height: 44px;
    margin-bottom: 34px;
    background: transparent;
    border: 0.862713px solid #FFFFFF;
    outline: none;
    padding-left: 20px;
    border-radius: 4.31357px;
    font-family: 'Montserrat';
    font-size: 17px;
    color: #fff;

}
.edit-user__form-buttons{
    margin-top: 58px;
    display: flex;
    justify-content: space-between;
}

.edit-user__form-buttons button{
    cursor: pointer;
    padding: 0 50px 0 50px;
    height: 33px;
    background: transparent;
    border: 1px solid #A54DFD;
    box-shadow: 0px 3.28892px 10.689px rgba(0, 0, 0, 0.1);
    border-radius: 6.68845px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.105em;
    color: #FFFFFF;
}
@media (max-width: 1560px) {
    .edit-user__form input{
        margin-bottom: 21px;
    }
    .edit-user__form-buttons{
        margin-top: 35px;
    }
}
@media (max-width: 560px) {
    .edit-user__form__container{
        width: 300px;
    }
}
