.search{
    width: 482px;
    margin-top: 0;
    background: #222222;
    box-shadow: 0px 2.6421px 8.58683px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 17px 19px 25px 19px;
}

.search__title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
    color: #FFFFFF;
}

.search__input{
    background: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    width: 100%;
    height: 42px;
    background: url("../../../../assets/img/search-icon.png") no-repeat scroll 7px 3px;
    padding-left: 50px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
}

@media (max-width: 1560px) {
    .search{
        width: 482px;
        height: 110px;
    }
    .search__title{
        font-family: 'Montserrat';
        font-style: normal;
        margin-bottom: 15px;
        font-weight: 400;
        font-size: 13.6567px;
        color: #FFFFFF;
    }
    .search__input{
        height: 41px;
        background-position: 5px;
        background-size: 20px;
        padding-left: 38px;
    }
}

@media (max-width: 810px) {
    .catalog__top{
        display: block;
    }
    .search{
        width: 100%;
        margin-bottom: 20px;
    }
}
